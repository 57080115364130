/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { colors } from '@mui/material';

// import pdf1 from 'assests/Pdf/Resources/NPSYPR_AnnualReport.pdf';

import Container from 'components/Container';


const mock = [
  {
    color: colors.lightBlue[500],
    title: '1. Investiture Ceremony ',
    subtitle: 'National Public School, Yeshwanthpur held the Investiture Ceremony for the newly appointed School Council members of the school on 1 July 2022.',
    href: '/investiture',
  },
  {
    color: colors.lightBlue[500],
    title: '2.	Valedictory Day',
    subtitle: '',
    href: '',
  },
  {
    color: colors.lightBlue[500],
    title: '3. Graduation Day ',
    subtitle: '',
    href: '',
  },
  // {
  //   color: colors.purple[500],
  //   title: 'Almanac',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/almanac_2021.pdf',
  
  // },
  // {
  //   color: colors.yellow[500],
  //   title: 'Staff Details',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/staff_details.pdf',

  // },
  // {
  //   color: colors.indigo[500],
  //   title: 'Text book List',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/list_of_text_book.pdf',

  // },
  // {
  //   color: colors.red[500],
  //   title: 'Declaration',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/books_declaration.pdf',
    
   
  // },
  // {
  //   color: colors.green[500],
  //   title: 'Fee Declaration',
  //   subtitle: '',
  //   href: 'https://www.npsypr.com/pdf/2020_21/resources/fee_declaration.pdf',
   
  // },
];

const Resources = () => {
  return (

    <Box >

      <Container>
        <Typography variant='h4' fontWeight={700} align='center'>
            <br/>Formal Official Ceremonies<br/> 2022-23<br/><br/>
          </Typography>
        <Grid container spacing={8}>
        
          {mock.map((item, i) => (
            <Grid item xs={12} sm={8} md={4} key={i}>
              <a href={item.href} style={{ textDecoration: 'none', color: 'black' }}>
              <Box
                
                bgcolor={'alternate.main'}>
                <Box component={Card} padding={4} width={1} height={1}  bgcolor={'alternate.main'}>
                  <Box display={'flex'} flexDirection={'column'}>
                  
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                    
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'flex-end'}
                    >
                      {/* <Button
                        href ={item.href}
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        Learn More
                      </Button> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
              </a>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>

  );
};

export default Resources;
